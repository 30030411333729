.footer {
    display: flex;
    justify-content: center;
    /* Center the items horizontally */
    align-items: flex-start;
    /* Align the items at the start of the cross-axis */
    gap: 200px;
    /* Space between the items */
    padding: 20px;
    background-color: #030018;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
}

.contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    color: #ADD9F4;
}

.value {
    margin-top: 5px;
    color: white;
    /* Space between label and value */
}