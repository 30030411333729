.services {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
    padding: 20px;
    margin-top: 100px;
    margin-bottom: 50px;
}

.service-box {
    background-color: #FAF9F6;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 30%;
    padding: 20px;
    height: 300px;
    transition: transform 0.3s, opacity 0.3s;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-box:hover {
    transform: scale(1.05);
}

.service-content {
    text-align: center;
}

.service-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.3s;
}

.service-box:hover .service-title {
    opacity: 0;
}

.service-title h3 {
    margin-top: 10px;
}

.service-title svg {
    font-size: 2rem;
    color: black;
}

.service-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    text-align: center;
    padding: 20px;
    color: black;
    width: 90%;

}

.service-box:hover .service-text {
    opacity: 1;
}