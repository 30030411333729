/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Arial', sans-serif;
  font-weight: 'bold';
  color: #ADD9F4;
}

h1 {
  font-size: 100px;
}

h2 {
font-size: 30px;

}

h3 {
  font-size: 35px;
}


p {
    font-family: 'Montserrat', sans-serif;
  font-weight:100;
}

body {
  background: linear-gradient(135deg, #040c2f, #030018);
  color: white;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

#root {
  min-height: 100vh;
}

