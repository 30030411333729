.technology-capabilities {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.technology-capabilities__left {
    flex: 1;
    padding: 10px;
    margin-left: 30px;
    margin-top: 100px;
}

.technology-capabilities__right {
    flex: 3;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-end;
}

.technology-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 600px;
  
}

.technology-item {
    display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        text-align: center;
}

.technology-logo {
    width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
}

.technology-logo:hover {
    transform: scale(1.1);
}

.technology-capabilities-heading {
    margin-left: 100px;
    margin-top: 100px;
}