/* .modal-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    border-radius: 5px;
}

.modal-content {
    background: #132059;
    padding: 20px;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: white;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px; 
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 40px;
    z-index: 1;
    color: white
}

.close-button:hover {
    color: #555;
} */

.modal-overlay {
    position: fixed;
    top: 10%;
    left: 10%;
    background: transparent;
    /* Slight background darkening for overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    overflow-y: hidden;
    z-index: 1000;
}

.modal-content {
    background: linear-gradient(135deg, #040c2f, #030018);
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: white;
    transform: translateY(80%);
    animation: slide-up 1.5s forwards;
    margin-bottom: 40px;
}

.modal-content.closing {
    animation: slide-up-and-away 1.5s forwards;
}

.live-project-link {
    color: white;
    text-decoration: none;
}

.close-button {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 40px;
    z-index: 1;
    color: white;
}

.close-button:hover {
    color: #555;
}

@keyframes slide-up {
    from {
        transform: translateY(80%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slide-up-and-away {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%);
    }
}
