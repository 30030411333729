.about-us {
    margin: 20px;
    width: 1200px;
    margin-left: 20px;
    font-size: 25px;
      line-height: 1.8;
      display: flex;
      flex-direction: column;
}

.about-us-heading {
    padding: 10; 
}

