.image-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the slider horizontally */
}

.image-slider img {
    max-width: 600px;
    max-height: 500px;
    margin-bottom: 50px;
    margin-top: 20px;
}

.arrow-button {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    margin: 0 10px;
    color: white;
}

.arrow-button:hover {
    color: #555;
}