.projects-page {
    padding: 40px;
    text-align: center;
}

.projects-page h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.projects-page p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.project-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
}