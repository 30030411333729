.page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    padding: 20px;
    box-sizing: border-box;
}